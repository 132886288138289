var textSlider = (function () {
    'use strict';

    var $ctrlPrev,       // Previous button
        $ctrlNext,       // Next button
        $sliderWrapper,  // Direct parent of slides
        currentSlide,    // Currently visible slide
        totalSlides = 0; // Total number of slides

    function init($prev, $next, $wrap) {
        $ctrlPrev = $prev;
        $ctrlNext = $next;
        $sliderWrapper = $wrap;
        currentSlide = 1;

        totalSlides = $sliderWrapper.children().length;

        $ctrlPrev.on('click', function () {
            if (currentSlide <= 1) {
                return;
            }

            changeSlide('backward');
        });

        $ctrlNext.on('click', function () {
            if (currentSlide >= totalSlides) {
                return;
            }

            changeSlide('next');
        });
    }

    function changeSlide(direction) {
        var oldClass = 'js-slide-' + currentSlide, // Class name to remove
            newClass = '';                         // Class name to add

        if (direction === 'backward') {
            currentSlide = currentSlide - 1;
        } else {
            currentSlide = currentSlide + 1;
        }

        newClass = 'js-slide-' + currentSlide;

        $sliderWrapper.removeClass(oldClass).addClass(newClass);
    }

    return {
        init: init
    };
})();


$(function () {
    textSlider.init($('#text-slider-prev'), $('#text-slider-next'), $('#text-slider-wrapper'));
});
