var formHandler = (function ($) {
    'use strict';


    /**
     * Holds messages returned from AJAX request
     */
    var messages;


    /**
     * ID of HTML target used to print messages to the page
     *
     * If one is not provided, then messages will be appended to the form
     */
    var messageTargetID;


    /**
     * Class name to be applied to messages container
     */
    var messageClassName = 'notification-box mb-su2';


    /**
     * Class name to be applied to messages container for success status
     */
    var successClassName = 'notification-box_success';


    /**
     * Class name to be applied to messages container for error status
     */
    var errorClassName = 'notification-box_error';


    /**
     * jQuery selector used to target loader via $form.find()
     *
     * Targets the submit button by default
     */
    var loaderSelector = '[type="submit"]';


    /**
     * Class name to toggle on the loader
     */
    var loaderClassName = 'btn--processing';


    /**
     * Submit form via AJAX
     *
     * @param {obj} $formObj - jQuery object containing the submitted form
     */
    var submitForm = function ($formObj) {

        // Bind form and message output target to this request to prevent
        // simultaneous submissions from interfering with one another
        var $submittedForm = $formObj;
        var requestMessageTargetID = getMessageTargetID();

        // Add hidden form field to identify ajax request on the server
        addAjaxField($submittedForm);

        // Activate loading indicator
        toggleLoader($submittedForm);

        $.ajax({
            method: 'post',
            url: 'form-handler.php',
            timeout: 0,
            data: $submittedForm.serialize(),
            dataType: 'json'
        })
            .always(function (data) {
                // Update the form with a new CSRF token
                $(document.activeElement)
                    .closest('form')
                    .find('[name=token]')
                    .val(data['new-csrf'])
            })
            .done(function (data) {
                toggleLoader($submittedForm);

                messages = getMessages(data);

                if (data.status === 'success') {
                    printMessages($submittedForm, messages, 'success', requestMessageTargetID);
                } else {
                    printMessages($submittedForm, messages, 'error', requestMessageTargetID);
                }
            })
            .fail(function (jqXHR, responseText, errorThrown) {
                // Deactivate loading indicator
                toggleLoader($submittedForm);

                // AJAX failed, probably connection error
                messages = ['There was a problem with the connection, please try again.'];
                printMessages($submittedForm, messages, 'connectionError', requestMessageTargetID);
                console.log(jqXHR);
            });
    };


    /**
     * Add hidden form field to identify ajax call on the server
     */
    var addAjaxField = function ($formObj) {
        $('<input />').attr('type', 'hidden')
            .attr('name', 'ajax-submit')
            .attr('value', 'true')
            .appendTo($formObj);
    };


    /**
     * Grab messages from response
     * data.messages may contain a single message or an object of message arrays
     *
     * @param   {obj}   data - jqXHR object
     * @returns {array} Array of messages
     */
    var getMessages = function (data) {
        var messages = [];

        if (typeof data.messages === 'string') {
            // just a single message
            messages.push(data.messages);
        } else if (typeof data.messages === 'object') {
            // object of message arrays
            for (var prop in data.messages) {
                if (data.messages.hasOwnProperty(prop)) {
                    data.messages[prop].forEach(function (element) {
                        messages.push(element);
                    });
                }
            }
        }

        return messages;
    };


    /**
     * Output messages to the page
     *
     * TODO: Improve handling of single vs multiple errors
     *
     * @param {obj} $formObj - jQuery object containing the form
     * @param {array} messages - Array containing message string(s)
     * @param {str} status - status used to control error output. Accepted values
     *   are success, error, and connectionError
     * @param {str} targetID - (optional) ID of element where messages should be
     *   inserted
     */
    var printMessages = function ($formObj, messages, status, targetID) {
        var containerTemplate = '<div class="%classes%">%messages%</div>',
            classString = getMessageClassName(),
            messageString = '',
            outputHTML = '',
            $appendedMessage, // used to check for and remove pre-existing messages
            $messageTarget;

        // Build class string depending on type of message to be displayed
        if (status === 'success') {
            // Add class to indicate success message
            classString += ' ' + getSuccessClassName();
        } else if (status === 'error' || status === 'connectionError') {
            // Add class to indicate error message
            classString += ' ' + getErrorClassName();
        }

        // Build messages string
        if (status === 'success' || status === 'connectionError') {
            messageString += '<p>' + messages[0] + '</p>';
        } else {
            messageString += '<p>Please correct the following errors:<p>';
            messageString += '<ul class="unordered-list">';
            messages.forEach(function (element, index) {
                messageString += '<li>' + messages[index] + '</li>';
            });
            messageString += '</ul>';
        }

        // Assemble message HTML
        outputHTML = containerTemplate.replace(/%classes%/, classString)
            .replace(/%messages%/, messageString);

        // Check if a targetID for outputting messages has been set. If false,
        // append them to the end of the form. If true, insert them into target
        if (typeof targetID === 'undefined') {
            // Grab appended message containers
            $appendedMessage = $formObj.find('.' + getMessageClassName());

            // If pre-existing messages exist, remove the entire container
            if ($appendedMessage.length > 0) {
                $appendedMessage.remove();
            }

            // Print messages to the end of the form
            $formObj.append(outputHTML);
        } else {
            $messageTarget = $('#' + targetID);
            if ($messageTarget.length > 0) {
                $messageTarget.html(outputHTML);
            }
        }
    };


    /**
     * Toggle ajax loading indicator
     *
     * @param {obj} $formObj - jQuery object containing the submitted form
     */
    var toggleLoader = function ($formObj) {
        var selector = getloaderSelector(),
            className = getLoaderClassName();
        $formObj.find(selector).toggleClass(className);
    };


    /**
     * Retrieve ID of element in which to insert messages
     */
    var getMessageTargetID = function () {
        return messageTargetID;
    };


    /**
     * Set ID of element in which to insert messages
     *
     * @param {str} targetID - Name of the ID to set without the #
     */
    var setMessageTargetID = function (targetID) {
        messageTargetID = targetID;
    };


    /**
     * Retrieve class name used for messages container of any status
     */
    var getMessageClassName = function () {
        return messageClassName;
    };


    /**
     * Set class name used for messages container of any status
     *
     * @param {str} className - Name of the class to use
     */
    var setMessageClassName = function (className) {
        messageClassName = className;
    };


    /**
     * Retrieve class name used for messages container of success status
     */
    var getSuccessClassName = function () {
        return successClassName;
    };


    /**
     * Set class name used for messages container of success status
     *
     * @param {str} className - Name of the class to use
     */
    var setSuccessClassName = function (className) {
        successClassName = className;
    };

    /**
     * Retrieve class name used for messages container of error status
     */
    var getErrorClassName = function () {
        return errorClassName;
    };


    /**
     * Set class name used for messages container of error status
     *
     * @param {str} className - Name of the class to use
     */
    var setErrorClassName = function (className) {
        errorClassName = className;
    };


    /**
     * Retrieve selector to target the loader
     */
    var getloaderSelector = function () {
        return loaderSelector;
    };


    /**
     * Set selector used to target the loader
     *
     * @param {str} selector - Selector to be used in jQuery selection
     */
    var setLoaderSelector = function (selector) {
        loaderSelector = selector;
    };


    /**
     * Retrieve name of class to toggle the loader
     */
    var getLoaderClassName = function () {
        return loaderClassName;
    };


    /**
     * Set name of class used to toggle the loader
     *
     * @param {str} className - Name of the class to use
     */
    var setLoaderClassName = function (className) {
        loaderClassName = className;
    };


    /**
     * API
     */
    return {
        submitForm: submitForm,
        setMessageTargetID: setMessageTargetID,
        setMessageClassName: setMessageClassName,
        setSuccessClassName: setSuccessClassName,
        setErrorClassName: setErrorClassName,
        setLoaderSelector: setLoaderSelector,
        setLoaderClassName: setLoaderClassName
    };
})(jQuery);


$(document).ready(function () {
    $('#inspection-request-form, #contact-form').on('submit', function (e) {
        var messageTargetID = $(this).attr('id') + '-messages';
        formHandler.setMessageTargetID(messageTargetID);
        formHandler.submitForm($(this));
        e.preventDefault();
    });
});
