function toggleMenu() {
    $('#page-container').toggleClass('page__container--menu-active');
    $('#menu-toggle-page').toggleClass('btn--menu--active');
    $('#page-header').toggleClass('page__header--menu--active');
}

// Document ready
$(function () {
    $('#menu-toggle-page, #menu-toggle-menu').click(function () {
        toggleMenu();
    });
});
